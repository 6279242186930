<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="row justify-content-md-center">
            <div class="col-sm-auto">
                <div class="card">
                    <div class="card-body">
                        <h6 class="text-center">{{ 'Reset Password' | translate }}</h6>
                        <form class="form-reset-pass" [formGroup]="form" (ngSubmit)="resetPassword()">
                            <form-textbox
                                labelText="{{ 'New Password' | translate }}"
                                formControlName="newPassword"
                                [textboxType]="'password'"></form-textbox>
                            <form-textbox
                                labelText="{{ 'Confirm password' | translate }}"
                                formControlName="confirmPassword"
                                [textboxType]="'password'"></form-textbox>
                            <button
                                class="btn btn-md btn-primary btn-block mt-3"
                                type="submit"
                                [disabled]="!form.valid">
                                {{ 'Reset Password' | translate }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</app-loader>
