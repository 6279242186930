import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AttachmentService } from '@common/services/attachment.service';
import { AuthenticationService } from '@common/services/authentication.service';
import { FileDownloadService } from '@common/services/file-download.service';
import { ModalService } from '@common/services/modal.service';
import { UserService } from '@common/services/user.service';
import { isMobile } from '@common/utils/isMobile';
import { requiredValidator } from '@common/validators/required-validator';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { UserIdleService } from 'angular-user-idle';
import { StateService } from 'app/singleton-services/state.service';
import { appUrl } from 'environments/environment.base';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';
import { AuthenticationStages } from '../../knownTypes/AuthenticationStages';
import { LoginCandidateResponse } from '../../models/LoginCandidateResponse';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    hasError = false;
    isBusy = false;
    showBackgroundImage = true;
    isPasswordExpired = false;
    loggedOutDueInactitvity = false;

    firstFactorForm: UntypedFormGroup;
    secondFactorForm: UntypedFormGroup;
    resetPasswordForm: UntypedFormGroup;

    twoFactorCode: string;

    twoFactorType: string;
    twoFactorPrefrenceSet = false;
    showResendCodeButton = false;

    twoFactorStage = false;
    chooseTwoFactorStage = false;
    firstFactorStage = true;
    resetPasswordStage = false;
    currentLanguage: any;

    private internalUsername: string;
    private internalPassword: string;
    returnUrl: string;
    isMobile = isMobile();

    private oidcEnabled = false;

    usernameAndPasswordMode = false;
    certificateMode = true;
    certificateLoginEnabled: boolean;
    localAppUrl = appUrl;

    constructor(
        private dialogService: ModalService,
        private toastyService: ToastrService,
        private router: Router,
        public translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private authService: AuthenticationService,
        public attachmentService: AttachmentService,
        public stateService: StateService,
        private userIdle: UserIdleService,
        private activeModal: DialogRef,
        private downloadService: FileDownloadService
    ) {
        this.firstFactorForm = new UntypedFormGroup({
            username: new UntypedFormControl('', requiredValidator()),
            password: new UntypedFormControl('', requiredValidator()),
            tac: new UntypedFormControl(false, requiredValidator())
        });
        this.secondFactorForm = new UntypedFormGroup({
            twoFactorCode: new UntypedFormControl('', requiredValidator())
        });
        this.resetPasswordForm = new UntypedFormGroup({
            currentPassword: new UntypedFormControl('', requiredValidator()),
            newPassword: new UntypedFormControl('', requiredValidator()),
            confirmPassword: new UntypedFormControl('', requiredValidator())
        });
        this.getCertificateLogin();

        if (this.localAppUrl.includes('localhost')) this.localAppUrl = 'https://pcsbg.portline.eu';
    }

    ngOnInit() {
        this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
        this.oidcEnabled = this.authService.oidcEnabled;
    }

    onPaste($pasteEvent) {
        const pastedText = $pasteEvent.clipboardData.getData('text') as string;
        $pasteEvent.preventDefault();
        this.secondFactorForm.patchValue({ twoFactorCode: pastedText.trim() });
    }

    public get getIsFirstFactorDisabled() {
        return this.firstFactorForm.value.tac == false || !this.firstFactorForm.valid;
    }

    getCertificateLogin() {
        this.attachmentService.getCertificateLogin().subscribe((data) => {
            this.certificateLoginEnabled = data.data.find((x) => x.active === true).enabled;
        });
    }

    oidcLogin() {
        this.authService.oidcAuthorize();
    }

    initiateLogin() {
        this.initializeAuthStages();
        const un = this.firstFactorForm.value.username;
        const pw = this.firstFactorForm.value.password;

        this.userService
            .login(un, pw)
            .pipe(
                first(),
                catchError((err) => {
                    this.hasError = true;
                    this.isBusy = false;
                    if (err.error.errorMessage === 'BEA11') {
                        this.isPasswordExpired = true;
                        this.setActiveAuthStage(AuthenticationStages.resetPassword);
                        return throwError(err);
                    }
                    this.setActiveAuthStage(AuthenticationStages.firstFactor);
                    return throwError(err);
                }),
                finalize(() => {
                    // change the language if the user has changed it in the login screen
                    if (this.currentLanguage) {
                        if (localStorage.getItem('language').toUpperCase() !== this.currentLanguage) {
                            this.userService
                                .setCurrentLanguage(
                                    localStorage.getItem('language').toUpperCase(),
                                    this.stateService.getSignInType()
                                )
                                .then((data) => {
                                    this.userService.setAuthenticationTokens(data.accessToken, data.refreshToken);
                                });
                        }
                    }
                })
            )
            .subscribe((response: LoginCandidateResponse) => {
                this.internalUsername = un;
                this.internalPassword = pw;
                this.currentLanguage = response.languageId;

                this.stateService.setSignInType(response.signInType);

                if (response.passwordExpired) {
                    this.setActiveAuthStage(AuthenticationStages.firstFactor);
                    this.isBusy = false;
                    this.usernameAndPasswordMode = false;
                    return;
                }

                this.userIdle.startWatching();
                window.localStorage.setItem('loggedout', 'false');

                this.userIdle.onTimerStart().subscribe(() => {
                    this.userIdle.resetTimer();
                    this.userIdle.stopWatching();
                    if (!this.loggedOutDueInactitvity) {
                        this.loggedOutDueInactitvity = true;
                        window.localStorage.setItem('loggedout', 'true');
                        const dialogElements = document.getElementsByClassName('k-dialog-wrapper');
                        Array.from(dialogElements).forEach((el) => {
                            el.setAttribute('style', 'display:none');
                        });

                        this.dialogService
                            .confirm(
                                this.translateService.instant(marker('Sign Out')) as string,
                                this.translateService.instant(
                                    marker('You have been logged out due to inactivity.')
                                ) as string,
                                this.translateService.instant(marker('Click here to log in again.')) as string,
                                null
                            )
                            .pipe()
                            .subscribe(() => {
                                this.router.navigate(['/logout']);
                            });
                    }
                });

                if (response.firstFactorSuccessful && response.codeSendingServiceFailed) {
                    this.setActiveAuthStage(AuthenticationStages.twoFactor);
                    this.isBusy = false;

                    this.hasError = false;
                    return;
                }

                if (response.askSessionHijack) {
                    this.hijack(response);
                    return;
                }

                if (!response.firstFactorSuccessful || response.hasError) {
                    this.setActiveAuthStage(AuthenticationStages.firstFactor);
                    this.isBusy = false;
                    return;
                }

                if (response.secondFactorSuccessful) {
                    this.setTokens(response.accessToken, response.refreshToken);
                    this.isBusy = false;
                    this.showBackgroundImage = false;
                    return;
                }

                if (response.firstFactorSuccessful && response.validateCertificate) {
                    this.certificateLogin();
                    return;
                }

                this.hasError = false;

                this.twoFactorPrefrenceSet = response.twoFactorPreference ? true : false;

                this.initiateNextAuthStage(response.twoFactorPreference);

                this.isBusy = false;
            });
    }

    resetPassword() {
        this.initializeAuthStages();
        const username = this.firstFactorForm.value.username;
        const body = this.resetPasswordForm.getRawValue();
        this.userService
            .resetPassword({ ...body, username })
            .pipe(
                first(),
                catchError((err) => {
                    this.hasError = true;
                    this.isBusy = false;
                    this.setActiveAuthStage(AuthenticationStages.resetPassword);
                    return throwError(err);
                })
            )
            .subscribe({
                next: () => {
                    this.toastyService.success(this.translateService.instant(marker('Password reset successfully')));
                    this.isPasswordExpired = false;
                    this.setActiveAuthStage(AuthenticationStages.firstFactor);
                    this.usernameAndPasswordMode = true;
                }
            });
    }

    setUser2FaPreference(twoFactorPreference: string) {
        this.initializeAuthStages();

        this.userService
            .setUser2FaPreference(this.internalUsername, this.internalPassword, twoFactorPreference)
            .pipe(
                first(),
                catchError((err) => {
                    this.hasError = true;
                    this.isBusy = false;
                    this.setActiveAuthStage(AuthenticationStages.chooseTwoFactorPreference);
                    return throwError(err);
                })
            )
            .subscribe((value) => {
                if (!value.twoFactorPreference && value.hasError) {
                    this.setActiveAuthStage(AuthenticationStages.chooseTwoFactorPreference);
                    this.isBusy = false;
                } else {
                    value.twoFactorPreference == 'EMAIL'
                        ? this.setActiveAuthStage(AuthenticationStages.twoFactor)
                        : this.setActiveAuthStage(AuthenticationStages.firstFactor);
                    this.isBusy = false;
                }
            });
    }

    logIn() {
        this.initializeAuthStages();

        this.userService
            .checkTwoFactorCode(this.internalUsername, this.internalPassword, this.secondFactorForm.value.twoFactorCode)
            .pipe(
                first(),
                catchError((err) => {
                    this.hasError = true;
                    this.isBusy = false;
                    this.showResendCodeButton = true;
                    this.setActiveAuthStage(AuthenticationStages.twoFactor);
                    return throwError(err);
                })
            )
            .subscribe((value) => {
                if (value.askSessionHijack) {
                    this.hijack(value);
                    return;
                }
                this.setTokens(value.accessToken, value.refreshToken);
                this.showBackgroundImage = false;
            });
    }

    resendCode() {
        this.isBusy = true;
        this.userService
            .resendTwoFactorCode(this.internalUsername)
            .pipe(
                first(),
                catchError((err) => {
                    this.hasError = true;
                    this.isBusy = false;
                    this.setActiveAuthStage(AuthenticationStages.twoFactor);
                    return throwError(err);
                })
            )
            .subscribe((value) => {
                if (value) {
                    this.setActiveAuthStage(AuthenticationStages.twoFactor);
                    this.showResendCodeButton = true;
                    this.secondFactorForm.controls.twoFactorCode.setValue(null);
                    this.isBusy = false;
                }
            });
    }

    certificateLogin() {
        this.isBusy = true;

        const data = {
            username: this.internalUsername,
            password: this.internalPassword
        };

        this.authService
            .attemptCertLogin(data)
            .pipe(
                first(),
                catchError((err) => {
                    this.hasError = true;
                    this.isBusy = false;
                    this.setActiveAuthStage(AuthenticationStages.firstFactor);
                    return throwError(err);
                })
            )
            .subscribe((response: LoginCandidateResponse) => {
                if (response.secondFactorSuccessful) {
                    this.setTokens(response.accessToken, response.refreshToken);
                    this.isBusy = false;
                    this.showBackgroundImage = false;
                    return;
                } else {
                    this.router.navigate(['certError']);
                }

                this.hasError = false;

                this.isBusy = false;
            });
    }

    toggleSignInMode() {
        this.certificateMode = !this.certificateMode;
        this.usernameAndPasswordMode = !this.usernameAndPasswordMode;
    }

    cancelResetPasswordMode() {
        this.isPasswordExpired = false;
        this.setActiveAuthStage(AuthenticationStages.firstFactor);
    }

    openBgSSO(isDemo = false) {
        window.open(`${this.localAppUrl}/sso${isDemo ? 'demo' : ''}/saml/login?ReturnUrl=${appUrl}/sso-login`, '_self');
    }

    /** Final auth stage - after successful password or two-factor login, set user authentication tokens,
     *  then get current user info
     */
    async setTokens(accessToken: string, refreshToken: string) {
        const status = await this.authService.authenticateWithTokens(accessToken, refreshToken);
        if (status) {
            this.router.navigate([this.returnUrl]);
        } else {
            this.router.navigate(['/error/500']);
        }
    }

    private initializeAuthStages() {
        this.isBusy = true;
        this.twoFactorStage = false;
        this.chooseTwoFactorStage = false;
        this.firstFactorStage = false;
        this.resetPasswordStage = false;
    }

    private initiateNextAuthStage(twoFactorPreference: string) {
        if (this.twoFactorPrefrenceSet) {
            this.setActiveAuthStage(AuthenticationStages.twoFactor);
            this.twoFactorType = twoFactorPreference;
        } else {
            this.setActiveAuthStage(AuthenticationStages.chooseTwoFactorPreference);
        }
    }

    public setActiveAuthStage(stage: string) {
        if (stage === AuthenticationStages.firstFactor) {
            this.firstFactorStage = true;
            this.chooseTwoFactorStage = false;
            this.twoFactorStage = false;
            this.resetPasswordStage = false;

            this.firstFactorForm.reset();
        }

        if (stage === AuthenticationStages.twoFactor) {
            this.twoFactorStage = true;
            this.firstFactorStage = false;
            this.chooseTwoFactorStage = false;
            this.resetPasswordStage = false;

            this.secondFactorForm.reset();
        }

        if (stage === AuthenticationStages.chooseTwoFactorPreference) {
            this.chooseTwoFactorStage = true;
            this.firstFactorStage = false;
            this.twoFactorStage = false;
            this.resetPasswordStage = false;
        }

        if (stage === AuthenticationStages.resetPassword) {
            this.resetPasswordStage = true;
            this.chooseTwoFactorStage = false;
            this.firstFactorStage = false;
            this.twoFactorStage = false;

            this.resetPasswordForm.reset();
        }
    }

    private async hijack(value) {
        const confirmDialogResult = await this.dialogService.confirm(
            this.translateService.instant(marker('User has existing session')),
            this.translateService.instant(
                marker('This action will establish new and log out existing session, are you sure?')
            ),
            this.translateService.instant(marker('YES')),
            this.translateService.instant(marker('Cancel'))
        );

        if (confirmDialogResult) {
            this.userService
                .hijack(this.internalUsername, this.internalPassword)
                .pipe(
                    first(),
                    catchError((err) => {
                        this.hasError = true;
                        this.isBusy = false;
                        this.setActiveAuthStage(AuthenticationStages.firstFactor);
                        return throwError(err);
                    })
                )
                .subscribe((x) => {
                    this.setTokens(x.accessToken, x.refreshToken);
                    this.isBusy = false;
                    this.showBackgroundImage = false;
                    return;
                });
        } else {
            this.setActiveAuthStage(AuthenticationStages.firstFactor);
            this.isBusy = false;
            return;
        }
    }

    public downloadRegistrationForm() {
        this.authService.downloadRegistrationForm().subscribe((reponse) => {
            this.downloadService.handleAttachmentDownload(reponse);
        });
    }
}
